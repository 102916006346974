<template>
  <div class="job_content_case_container">
    <div class="title">
      {{ item.title }}
    </div>
    <div class="description">
      <h3 class="description_title">岗位描述:</h3>
      <ul>
        <li v-for="(item, index) in item.descriptionList" :key="index">
          {{ item }}
        </li>
      </ul>
    </div>
    <div class="expect">
      <h3 class="expect_title">我们的期望:</h3>
      <ul>
        <li v-for="(item, index) in item.expectList" :key="index">
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JobContentCase',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.job_content_case_container {
  .title {
    width: 464px;
    height: 85px;
    line-height: 85px;
    background: #1651dd;
    font-size: 48px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    margin-bottom: 35px;
  }
  .description {
    width: 693px;
    height: 337px;
    background: #f7f9fd;
    padding: 15px 41px 11px 19px;
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
    .description_title {
      font-size: 19px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #101010;
      margin-bottom: 40px;
    }
    ul {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      flex: 1;
    }
    li {
      font-size: 19px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #101010;
      line-height: 27px;
    }
  }
  .expect {
    width: 693px;
    height: 389px;
    background: #f7f9fd;
    padding: 15px 41px 11px 19px;
    display: flex;
    flex-direction: column;
    .expect_title {
      font-size: 19px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #101010;
      margin-bottom: 40px;
    }
    ul {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    li {
      font-size: 19px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #101010;
      line-height: 27px;
    }
  }
}
</style>
