<template>
  <div class="job_content_container w">
    <h1 class="job_title">上海泉邦网络科技有限公司</h1>
    <div class="job_list">
      <div class="job_case" v-for="item in job" :key="item.id">
        <JobCase :item="item"></JobCase>
      </div>
    </div>
    <div class="job_content_case"></div>
  </div>
</template>

<script>
import JobCase from './job_case.vue'
export default {
  name: 'JobContent',
  components: {
    JobCase
  },
  data() {
    return {
      job: [
        {
          id: 1,
          title: '【职位1：网络营销】',
          descriptionList: [
            '1、根据客户的需求，通过公司网络平台预约、拜访、面谈客户；',
            '2、维护客户关系，定期与合作客户进行沟通，建立良好的长期合作关系；',
            '3、与客户进行有效沟通，了解客户的需求,寻找销售机会并完成销售业绩；',
            '4、认真完成工作计划，确保按公司要求，执行相应工作流程；',
            '5、完成上级领导交办的其它工作。'
          ],
          expectList: [
            '1、具有良好的自我抗压能力；',
            '2、热爱营销岗位，对营销工作有较高的热情；',
            '3、工作细致严谨，并具有战略前瞻性思维；',
            '4、具有高度的工作热情和责任感；',
            '5、想要挑战高薪或努力奋斗的外地人；',
            '6、男女不限。'
          ]
        },
        {
          id: 2,
          title: '【职位2：销售顾问】',
          descriptionList: [
            '1、根据客户的需求，通过公司网络平台预约、拜访、面谈客户；',
            '2、维护客户关系，定期与合作客户进行沟通，建立良好的长期合作关系；',
            '3、与客户进行有效沟通，了解客户的需求，深度发掘及跟进潜在客户；',
            '4、根据公司要求参加论坛、商会、等活动开发客户；',
            '5、 能够接受出差，参与公司分公司业务开展；',
            '6、负责公司普惠金融产品及教育业务的推介，接待客户促进成交；',
            '7、完成上级领导交办的其它工作；'
          ],
          expectList: [
            '1、具有优秀的语言表达与会话沟通能力；',
            '2、为人正直、坦诚、豁达、自信；',
            '3、具有高度的工作热情与良好的团队合作精神； ',
            '4、具有较强的观察力、应变和抗压能力；',
            '5、有销售方面一年以上的工作经验；',
            '6、大专及以上学历，相关专业、同岗位者优先录用； '
          ]
        },
        {
          id: 3,
          title: '【职位3：网络客服】',
          descriptionList: [
            '1、协助合伙人公司的运营管理工作(客户邀约、会务管控、客户沙龙、招商会、培训落地等)提高客户满意度；',
            '2、接洽、维护高端学员，参与项目的管理和学员活动；',
            '3、参加公司研讨会（商学院、实战管理、演说、企业内训、顾问式咨询诊断等），辅导客户培训学习；',
            '4、做好客户信息的收集、整理和反馈，提供行业方案所需基础数据信息；',
            '5、协助合伙人组建团队。'
          ],
          expectList: [
            '1、大专以上学历，形象大方得体，气质佳，80后90后，两年以上工作经验；',
            '2、善于沟通，有亲和力，有责任心，有耐心；',
            '3、有责任感，能自我激励，能够承受工作压力；',
            '4、有销售或高端客户服务经验。（教育培训、咨询、星级酒店、高档房产、奢侈品等行业有相关经验者优先）。'
          ]
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.job_container {
  padding: 85px 220px 0 183px;
  .job_title {
    font-size: 64px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #024580;
    line-height: 89px;
    margin-bottom: 89px;
    margin-top: 85px;
  }
  .job_list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .job_case {
    flex: 1;
    margin-bottom: 32px;
    margin-right: 43px;
  }
}
</style>
