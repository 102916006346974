<template>
  <div class="job_container">
    <!-- 顶部导航占位 -->'
    <QbTopnavPlaceholder></QbTopnavPlaceholder>

    <!-- 人才招聘标题 -->
    <QbTitle :title="title" :complement="complement">
      <a
        target="_blank"
        href="https://www.zhipin.com/gongsi/1e1752c542c0580c03J429W5.html?ka=search_list_company_1_custompage"
        class="boss_job"
        @mouseenter="isShow = false"
        @mouseleave="isShow = true"
      >
        <img v-if="isShow" src="@/assets/images/about/go_boss.png" alt="" />
        <img v-else src="@/assets/images/about/go_boss_hover.png" alt="" />
      </a>
    </QbTitle>

    <!-- 人才招聘内容 -->
    <JobContent></JobContent>

    <!-- 人才招聘福利 -->
    <JobWelfare></JobWelfare>
  </div>
</template>

<script>
import JobContent from './components/job-content.vue'
import JobWelfare from './components/job_welfare.vue'
export default {
  name: 'Job',
  components: {
    JobContent,
    JobWelfare
  },
  data() {
    return {
      title: '泉邦集团人才招聘',
      complement: 'Talent recruitment',
      isShow: 'true'
    }
  }
}
</script>

<style lang="less" scoped>
.boss_job {
  display: block;
  width: 445px;
  height: 45px;
  margin-top: 119px;
  margin-left: 319px;
}
</style>
