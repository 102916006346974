<template>
  <div class="job_walfare_container w">
    <h1 class="job_walfare_title">{{ title }}</h1>
    <ul class="job_walfare_content">
      <li v-for="(item, index) in jobwalfareList" :key="index">
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'JobWalfare',
  data() {
    return {
      title: '我们的基本福利',
      jobwalfareList: [
        '1、无责底薪+丰厚的提成 + 绩效奖金 + 团队业绩奖+公司分红；表现优秀+公司股票、期权。综合：6K---15K元/月，上不封顶；',
        '2、入职新员工享有带薪统一学习培训；',
        '3、团队部门国内、国外旅游；',
        '4、完善的社会保险五险一金；',
        '5、上班时间：08:30am-18:00pm，中午休息一个半小时。',
        '6、待遇面议，可发送简历至邮箱：1633521957@qq.com。'
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.job_walfare_container {
  margin-top: 124px;
  margin-bottom: 391px;
  .job_walfare_title {
    width: 464px;
    height: 85px;
    line-height: 85px;
    background: #1651dd;
    font-size: 48px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    margin-bottom: 35px;
  }
  .job_walfare_content {
    width: 1489px;
    height: 337px;
    background: #f7f9fd;
    padding: 15px 108px 11px 19px;
    font-size: 19px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #101010;
    line-height: 27px;
    li {
      margin-bottom: 25px;
    }
  }
}
</style>
